<template>
  <div class="container">
    <main class="offset bottom gray" style="min-height: 100vh; padding-top: 40px">
      <div>
        <p class="divider">請選擇</p>
        <nav class="nav-stacked nav-cart-select">
          <router-link
            v-for="(item, key) in getCartList"
            :key="key"
            :to="{ path: '/m/cart/main', query: { type: item.cartType } }"
            @click.native="callTracking(item)"
            class="nav-item">
            {{ item.cartName }}
            <span class="tag tag-secondary circle btn-round">{{ item.count }}</span>
          </router-link>
        </nav>
      </div>
      <center-slider v-if="isNotFromMuchMuch"></center-slider>
    </main>
  </div>
</template>

<script>
import { commitMutation, renderState } from '@/helpers/vuex';
import { isNotFromMuchMuch } from '@/helpers/is-from-app';
import centerSlider from '@/components/mobile/slider/center-slider.vue';

const components = {
  centerSlider,
};

const methods = {
  callTracking(item) {
    // 字串轉數字 for cartDetail API
    const cartType = parseInt(item.cartType, 10);
    // 供呼叫 cart-detail API 用
    commitMutation('CartDetail', 'cartType', cartType);
  },
};

const computed = {
  // 取得列表 與 cart.vue 重複
  getCartList() {
    return renderState('Cart', 'cartInfo');
  },
  isNotFromMuchMuch,
};

export default {
  name: 'mart-list',
  components,
  computed,
  methods,
};
</script>
