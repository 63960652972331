<template>
  <div class="container">
    <!--有車-->
    <header-cart v-if="!emptyCart">
      <template slot="header-middle">購物車明細</template>
      <template slot="header-right">
        <!--收藏按鈕-->
        <a v-if="isNotFromMuchMuch" :href="onlineUrl('/m/Favorite')" class="btn-link">
          <i class="icon icon-heart"></i>
        </a>
      </template>
    </header-cart>
    <!--空車-->
    <choice-empty
      v-if="emptyCart"
      :isLogin="this.isLogin"
      :isMobile="isMobile"
      :favoriteUrl="onlineUrl('/m/Favorite')"></choice-empty>
    <mart-list v-else :isMobile="isMobile"></mart-list>
  </div>
</template>

<script>
import { renderState } from '@/helpers/vuex';
import { isNotFromMuchMuch } from '@/helpers/is-from-app';
import fullBackground from '@/mixins/full-background';
import callGetCartCount from '@/mixins/call-get-cart-count';
import choiceEmpty from '@/components/common/choice-empty.vue';
import martList from '@/components/mobile/main/mart-list.vue';
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  choiceEmpty,
  headerCart,
  martList,
};

// 購物車裡的總數量
const emptyCart = () => renderState('Cart', 'cartTotal') === 0;

// 收藏匣連結
const onlineUrl = (uri) => `${process.env.VUE_APP_ONLINE}${uri}`;

const mounted = function () {
  this.getCart();
  this.$store.dispatch('Cart/fetchBanners');
};

export default {
  name: 'cart-choice',
  components,
  props: {
    isMobile: Boolean,
  },
  computed: {
    emptyCart,
    isNotFromMuchMuch,
  },
  methods: {
    onlineUrl,
  },
  mounted,
  mixins: [fullBackground, callGetCartCount],
};
</script>

<style>
#app.mobile {
  padding-bottom: 0;
}
</style>
