/**
 * main tag 最小高度調整
 */
const fullBackground = {
  data() {
    return {
      timer: false,
    };
  },
  methods: {
    // 背景高度運算，讓背景滿版
    fullBackground() {
      const $wrapper = document.querySelector('#app.mobile');

      if ($wrapper === null) {
        return false;
      }

      const $windowHeight = window.innerHeight || 0;
      const $wrapperHeight = $wrapper.offsetHeight || 0;

      $wrapper.classList.add('gray');
      // 判斷wrapper高度是否小於window高度
      $wrapper.style['min-height'] = ($windowHeight > $wrapperHeight ? $windowHeight : 0) + 'px';
    },
  },
  mounted() {
    this.$nextTick(function () {
      const then = this;
      window.addEventListener('resize', function () {
        if (!this.timer) {
          this.timer = true;
          setTimeout(() => {
            then.fullBackground();
            then.timer = false;
          }, 1000);
        }
      });

      // first
      this.fullBackground();
    });
  },
  destroyed() {
    const $wrapper = document.querySelector('#app.mobile');

    if ($wrapper === null) {
      return false;
    }
    $wrapper.classList.remove('gray');
    $wrapper.style['min-height'] = '';
  },
};

export default fullBackground;
